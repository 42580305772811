.table-parent {
  margin-left: 20vw;
  margin-top: 2vw;
}

tr,
td {
  box-sizing: content-box;
}
table {
  border-collapse: collapse;
}
th {
  margin-right: 10vw;
  border: 1px solid black;
}
td {
  border: 1px solid black;
}
.add-button {
  margin-left: 80vw;
  margin-top: 1vw;
  padding: 1vw;
  cursor: pointer;
  /* width: 8vw; */
}
td {
  padding: 2vw;
}

.actions {
  padding: 0.5vw;
  margin-right: 0.4vw;
  cursor: pointer;
}
